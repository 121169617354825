var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "w-full" },
    [
      _c(
        "meta-actions-header",
        {
          staticClass: "mb-8",
          attrs: { "is-loading": _vm.isLoading },
          scopedSlots: _vm._u(
            [
              !_vm.isLoading
                ? {
                    key: "header",
                    fn: function () {
                      return [
                        _c(
                          "breadcrumb",
                          { staticClass: "flex flex-1 text-gray-700" },
                          [
                            _vm._v(": "),
                            _vm.currentProduct
                              ? _c("span", { staticClass: "text-orange-500" }, [
                                  _vm._v(
                                    " " + _vm._s(_vm.currentProduct.name) + " "
                                  ),
                                ])
                              : _vm._e(),
                          ]
                        ),
                      ]
                    },
                    proxy: true,
                  }
                : null,
            ],
            null,
            true
          ),
        },
        [
          _vm.currentProduct
            ? _c(
                "template",
                { slot: "header" },
                [
                  _c("product-svg", {
                    staticClass: "inline-flex w-8 h-8 mr-3",
                  }),
                  _vm._v(" " + _vm._s(_vm.currentProduct.name) + " "),
                ],
                1
              )
            : _vm._e(),
          _vm.currentProduct
            ? _c(
                "template",
                { slot: "meta" },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "flex mt-2 text-sm text-gray-800 leading-5 sm:mr-6",
                    },
                    [
                      _c("advertiser-svg", {
                        staticClass: "flex-shrink-0 w-5 h-5 mr-3 text-gray-800",
                      }),
                      _c("p", [
                        _vm._v(_vm._s(_vm.currentProduct.advertiser.name)),
                      ]),
                    ],
                    1
                  ),
                  _c("meta-calendar", {
                    attrs: {
                      "start-date": _vm.currentProduct.initialDate,
                      "end-date": _vm.currentProduct.endDate,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        2
      ),
      _c("tabs", {
        attrs: {
          entity: _vm.currentProduct,
          "second-entity": _vm.currentProduct
            ? _vm.currentProduct.comments
            : [],
          "tabs-data": _vm.tabs,
          "is-loading": _vm.isLoading,
        },
        on: {
          update: _vm.onTabsUpdate,
          create: _vm.onTabsCreate,
          delete: _vm.onTabsDelete,
          reload: _vm.onTabReload,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }