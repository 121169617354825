var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    { attrs: { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 1 16 15" } },
    [
      _c("title", [_vm._v("Product_1")]),
      _c("g", { attrs: { id: "Capa_2", "data-name": "Capa 2" } }, [
        _c("g", { attrs: { id: "Capa_1-2", "data-name": "Capa 1" } }, [
          _c("path", {
            staticClass: "fill-current",
            attrs: {
              d: "M12.08,11.8l-2.6-1.33a.63.63,0,0,1-.31-.56V6a.6.6,0,0,1,\n    .31-.56l2.6-1.34c.24-.12.5.09.5.4v6.9C12.58,11.71,12.32,\n    11.92,12.08,11.8Z",
            },
          }),
          _c("path", {
            staticClass: "fill-current",
            attrs: {
              d: "M13.71,6.45a.39.39,0,0,1-.34-.21.38.38,0,0,1,\n    .16-.51l1.33-.68a.38.38,0,0,1,.51.16.37.37,0,0,\n    1-.16.51l-1.33.69A.59.59,0,0,1,13.71,6.45Z",
            },
          }),
          _c("path", {
            staticClass: "fill-current",
            attrs: {
              d: "M4.24,7.2h0c0,.14-.07.32-.11.46L4,\n    8.18h.54l-.15-.52A3.9,3.9,0,0,1,4.24,7.2Z",
            },
          }),
          _c("path", {
            staticClass: "fill-current",
            attrs: {
              d: "M6.44,7.83c-.25,0-.37.22-.37.5s.14.48.37.48a.31.31,\n    0,0,0,.31-.26.51.51,0,0,0,0-.13V8.19s0-.06,0-.09A.32.32,\n    0,0,0,6.44,7.83Z",
            },
          }),
          _c("path", {
            staticClass: "fill-current",
            attrs: {
              d: "M7.85,5.6H2.64A.63.63,0,0,0,2,6.23V9.67a.64.64,0,0,\n    0,.64.64h.2V13.1a.83.83,0,0,0,.83.83.84.84,0,0,0,\n    .84-.83V10.31H7.85a.64.64,0,0,0,.63-.64V6.23A.63.63,0,\n    0,0,7.85,5.6ZM4.77,9.21l-.18-.62h-.7l-.17.62H3.16l.73-2.42h.72l.75,\n    2.42Zm2.07,0,0-.26h0a.62.62,0,0,1-.55.29.8.8,0,0,\n    1-.75-.89.84.84,0,0,1,.79-.94.54.54,0,0,1,.46.2h0v-1h.54v2c0,\n    .2,0,.41,0,.53Z",
            },
          }),
          _c("path", {
            staticClass: "fill-current",
            attrs: {
              d: "M15.62,8.33H13.53a.38.38,0,0,1,\n    0-.76h2.09a.38.38,0,0,1,0,.76Z",
            },
          }),
          _c("path", {
            staticClass: "fill-current",
            attrs: {
              d: "M15,10.9a.58.58,0,0,1-.18,0l-1.33-.69a.38.38,0,\n    1,1,.35-.67l1.33.68a.38.38,0,0,1-.17.72Z",
            },
          }),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }