var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mt-2 flex text-sm leading-1 text-gray-800 text-left" },
    [
      _c("calendar-svg", {
        staticClass: "flex-shrink-0 mr-3 h-5 w-5 text-gray-800",
      }),
      _c("div", { staticClass: "flex flex-col" }, [
        _vm.startDate
          ? _c(
              "div",
              { staticClass: "flex" },
              [
                _vm._v(" Starts on "),
                _c("date-tooltip", {
                  staticClass: "ml-1",
                  attrs: { date: _vm.startDate },
                }),
              ],
              1
            )
          : _c("p", [_vm._v("Undefined start date")]),
        _vm.endDate
          ? _c(
              "div",
              { staticClass: "flex" },
              [
                _vm._v(" Ends on "),
                _c("date-tooltip", {
                  staticClass: "ml-1",
                  attrs: { date: _vm.endDate },
                }),
              ],
              1
            )
          : _c("p", [_vm._v("Undefined end date")]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }