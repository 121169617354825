import { api } from './index';
/**
 *
 * @param {string} resource
 * @param {string} url
 * @param {string} id
 * @param {Object} currentApi
 * @returns {Promise.<string>}
 */
export async function deleteById(resource = '', id = '', currentApi = null) {
  if (currentApi === null) currentApi = api;
  const partialUrl = currentApi.createUrl({
    [resource]: id,
  });
  await currentApi.delete(partialUrl);
  return id;
}
