var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    { attrs: { xmlns: "http://www.w3.org/2000/svg", viewBox: "2 2 14 14" } },
    [
      _c("title", [_vm._v("ad")]),
      _c("g", { attrs: { id: "Capa_2", "data-name": "Capa 2" } }, [
        _c("g", { attrs: { id: "Capa_1-2", "data-name": "Capa 1" } }, [
          _c("path", {
            staticClass: "fill-current",
            attrs: {
              d: "M12.93,4.69l.09,0,2.11,1.16a.2.2,0,0,1,.09.17v5.88a.18.18,0,0,\n   1-.1.17l-2.53,1.2-.08,0H2.78V4.69H12.93m0-.78H2.65A.65.65,0,0,0,\n   2,4.56v8.88a.65.65,0,0,0,.65.65h9.86a1,1,0,0,0,.41-.09l2.53-1.21a1,\n   1,0,0,0,.55-.87V6a1,1,0,0,0-.5-.85L13.39,4a.93.93,0,0,0-.46-.12Z",
            },
          }),
          _c("path", {
            staticClass: "fill-current",
            attrs: {
              d: "M6.34,10.09,6,11.16h-1L6.34,7H7.58l1.29,4.17h-1l-.32-1.07Zm1.06-.7L7.14,\n      8.5C7.06,8.26,7,8,6.93,7.7h0c-.06.25-.12.56-.19.8l-.24.89Z",
            },
          }),
          _c("path", {
            staticClass: "fill-current",
            attrs: {
              d: "M12.24,6.77v3.49c0,.34,0,.7,0,.9h-.83l0-.44h0a1.06,\n      1.06,0,0,1-.95.51A1.39,1.39,0,0,1,9.13,9.68a1.44,1.44,0,0,\n      1,1.36-1.61.9.9,0,0,1,.79.34h0V6.77ZM11.3,9.42a1,1,0,0,0,\n      0-.17.56.56,0,0,0-.55-.46c-.42,0-.65.38-.65.86s.26.83.65.83a.54.54,0,0,0,.54-.45.78.78,0,0,0,0-.22Z",
            },
          }),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }