import { api } from '.';
import QueryParamsBuilder from '@/model/shared/QueryParamsBuilder';

/**
 *
 * @param {string} resource
 * @param {string} id
 * @param {QueryParamsBuilder} params
 * @param {Object} currentApi
 * @returns {Promise.<any>}
 */
export async function get(resource = '', id = null, params = new QueryParamsBuilder(), currentApi = null) {
  if (currentApi === null) currentApi = api;

  const partialUrl = currentApi.createUrl({
    [resource]: id,
  });

  const { data } = await currentApi.get(partialUrl, params?.build ? params.build() : params);
  return data;
}
