var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.breadcrumbs.length > 0
    ? _c("div", { staticClass: "flex flex-row items-center relative z-1" }, [
        _c(
          "div",
          { staticClass: "flex" },
          [
            _vm._l(_vm.breadcrumbs, function (breadcrumb, index) {
              return [
                breadcrumb
                  ? _c(
                      "div",
                      {
                        key: `breadcrumb_${index}_${_vm.breadcrumbs.length}`,
                        staticClass: "flex items-center",
                      },
                      [
                        _c(
                          "router-link",
                          {
                            class: _vm.classByState(index),
                            attrs: { to: _vm.getRoute(breadcrumb, index) },
                          },
                          [
                            index > 0
                              ? _c(
                                  "span",
                                  {
                                    class: {
                                      "text-xs md:text-sm text-gray-600 ":
                                        index < _vm.breadcrumbs.length - 1,
                                    },
                                  },
                                  [
                                    _vm._v(" " + _vm._s(breadcrumb.text)),
                                    index === _vm.breadcrumbs.length - 1
                                      ? _vm._t("default")
                                      : _vm._e(),
                                  ],
                                  2
                                )
                              : _c("home-svg", { staticClass: "h-5 hidden" }),
                          ],
                          1
                        ),
                        index > 0 && index < _vm.breadcrumbs.length - 1
                          ? _c(
                              "div",
                              { staticClass: "px-1" },
                              [
                                _c("caret-svg", {
                                  staticClass: "h-4 text-gray-600",
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            }),
          ],
          2
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }