var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    { attrs: { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 18 18" } },
    [
      _c("path", {
        staticClass: "fill-current",
        attrs: {
          d: "M9.74,9l-.45-.45L6.62,5.89a.79.79,0,0,1-.16-1,.79.79,0,0,1,1.18-.23l.19.17C9,6,10.18,7.2,11.36,8.37a.82.82,0,0,1,0,1.23L7.74,13.24a.8.8,0,0,1-1.3-.18.81.81,0,0,1,.19-1l3-3A.53.53,0,0,1,9.74,9Z",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }