import { mapGetters } from 'vuex';
import { ACTIVE_CONTEXT } from '@/store/modules/context/keys';

export const activeClientMixin = {
  computed: {
    ...mapGetters({
      activeContext: ACTIVE_CONTEXT,
    }),
  },
  watch: {
    '$route.params.clientId': async function () {
      await this.onActiveClientChange();
    },
  },
  methods: {
    // This method is re write on indexMixin.
    async onActiveClientChange() {
      await this.$router.replace({ name: this.activeContext?.getDefaultComponent() });
    },
  },
};
