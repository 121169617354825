<template>
  <div v-if="breadcrumbs.length > 0" class="flex flex-row items-center relative z-1">
    <div class="flex">
      <template v-for="(breadcrumb, index) in breadcrumbs">
        <div v-if="breadcrumb" :key="`breadcrumb_${index}_${breadcrumbs.length}`" class="flex items-center">
          <router-link :to="getRoute(breadcrumb, index)" :class="classByState(index)">
            <span v-if="index > 0" :class="{ 'text-xs md:text-sm text-gray-600 ': index < breadcrumbs.length - 1 }">
              {{ breadcrumb.text }}<slot v-if="index === breadcrumbs.length - 1" />
            </span>
            <home-svg v-else class="h-5 hidden" />
          </router-link>
          <div v-if="index > 0 && index < breadcrumbs.length - 1" class="px-1">
            <caret-svg class="h-4 text-gray-600" />
          </div>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { BREADCRUMBS } from '@/store/modules/breadcrumbs/keys';
import HomeSvg from '../icons/HomeSvg';
import CaretSvg from '../icons/CaretSvg';

export default {
  name: 'Breadcrumb',
  components: {
    HomeSvg,
    CaretSvg,
  },
  computed: {
    ...mapGetters({
      breadcrumbs: BREADCRUMBS,
    }),
    breadcrumbsSize() {
      return this.breadcrumbs.length - 1;
    },
  },
  methods: {
    classByState(index) {
      return this.isLast(index) ? 'cursor-default' : 'hover:text-gray-700 hover:underline';
    },
    getRoute(breadcrumb, index) {
      if (this.isLast(index) || !breadcrumb?.composeRoute) {
        return { name: '' };
      }
      return breadcrumb.composeRoute();
    },
    isLast(index) {
      return index === this.breadcrumbsSize;
    },
  },
};
</script>
