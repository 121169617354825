export class DateFormatter {
  constructor(options, locale = 'en-US') {
    this.locale = locale;
    this.options = options;
  }
}
export const spanishDateFormatter = new DateFormatter({ day: '2-digit', month: '2-digit', year: 'numeric' }, 'es-ES');
export const spanishTimeFormatter = new DateFormatter({ hour: '2-digit', minute: '2-digit' }, 'es-ES');
export const spanishDateTimeFormatter = new DateFormatter(
  { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit' },
  'es-ES'
);
export const monthDateFormatter = new DateFormatter({ month: 'long', year: 'numeric' });
