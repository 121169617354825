var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.displayDate
    ? _c(
        _vm.popoverComponent,
        {
          tag: "component",
          staticClass: "flex-none",
          class: _vm.classOnPopover,
          attrs: {
            "text-info": _vm.displayDate.toUTCString(),
            position: "right",
          },
        },
        [
          _vm.showTime
            ? _c("span", [
                _vm._v(_vm._s(_vm._f("applyTimeFormatter")(_vm.displayDate))),
              ])
            : _c("span", [
                _vm._v(_vm._s(_vm._f("applyDateFormatter")(_vm.displayDate))),
              ]),
        ]
      )
    : _c("span", [_vm._v("-")])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }