<template>
  <div class="w-full">
    <meta-actions-header class="mb-8" :is-loading="isLoading">
      <template v-if="!isLoading" #header>
        <breadcrumb class="flex flex-1 text-gray-700"
          >:
          <span v-if="currentProduct" class="text-orange-500">
            {{ currentProduct.name }}
          </span>
        </breadcrumb>
      </template>
      <template v-if="currentProduct" slot="header">
        <product-svg class="inline-flex w-8 h-8 mr-3" />
        {{ currentProduct.name }}
      </template>

      <template v-if="currentProduct" slot="meta">
        <div class="flex mt-2 text-sm text-gray-800 leading-5 sm:mr-6">
          <advertiser-svg class="flex-shrink-0 w-5 h-5 mr-3 text-gray-800" />
          <p>{{ currentProduct.advertiser.name }}</p>
        </div>
        <meta-calendar :start-date="currentProduct.initialDate" :end-date="currentProduct.endDate" />
      </template>
    </meta-actions-header>

    <tabs
      :entity="currentProduct"
      :second-entity="currentProduct ? currentProduct.comments : []"
      :tabs-data="tabs"
      :is-loading="isLoading"
      @update="onTabsUpdate"
      @create="onTabsCreate"
      @delete="onTabsDelete"
      @reload="onTabReload"
    />
  </div>
</template>

<script>
import { deleteModalMixin } from '@/mixins/delete/deleteModal';
import { PRODUCT_RESOURCE } from '@/services/keys';
import { ThirdPartyApi } from '@/services/api/ThirdPartyApi';
import { applyDateFormatters, stringToDate } from '@/filters/dateFilters';
import ProductSvg from '@/components/icons/ProductSvg';
import MetaActionsHeader from '@/components/organisms/shared/MetaActionsHeader';
import AdvertiserSvg from '@/components/icons/AdvertiserSvg';
import { getProductById } from '@/services/modules/ThirdParty/product/getProductById';
import { spanishDateFormatter } from '@/model/shared/DateFormatter';
import Tabs from '@/components/atoms/Tabs';
import { CONTEXTS } from '@/model/shared/contexts';
import { activeClientMixin } from '@/mixins/common/activeClientMixin';
import MetaCalendar from '@/components/molecules/modules/thirdParty/MetaCalendar/MetaCalendar';
import { comments, metrics } from '@/router/private/modules/thirdParty/demand/product/edit';
import Tab from '@/model/shared/Tab';
import Breadcrumb from '@/components/atoms/Breadcrumb';

export default {
  name: 'ProductEdit',
  components: {
    Breadcrumb,
    MetaCalendar,
    ProductSvg,
    MetaActionsHeader,
    AdvertiserSvg,
    Tabs,
  },
  filters: {
    stringToDate,
    applyDateFormatter: date => applyDateFormatters(date, spanishDateFormatter),
  },
  mixins: [activeClientMixin, deleteModalMixin],
  props: {
    productId: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    currentApi: new ThirdPartyApi(),
    resource: PRODUCT_RESOURCE,
    currentProduct: null,
    isLoading: true,
    tabs: [new Tab('Metrics', metrics), new Tab('Comments', comments)],
  }),
  computed: {
    clientId() {
      return this.$route.params.clientId;
    },
    currentLocation() {
      return this.$route.name;
    },
    isComments() {
      return this.currentLocation === `${CONTEXTS.THIRD_PARTY.baseUrl}-products-comments`;
    },
  },
  beforeMount() {
    this.getProductById();
  },
  methods: {
    onActiveClientChange() {
      this.$router.replace({ name: `${CONTEXTS.THIRD_PARTY.baseUrl}-products` });
    },
    onTabReload() {
      this.getProductById();
    },
    onTabsCreate() {
      this.getProductById();
    },
    onTabsDelete() {
      this.getProductById();
    },
    onTabsUpdate() {
      this.getProductById();
    },
    getMetricsRouteName() {
      return `${CONTEXTS.THIRD_PARTY.baseUrl}-products-metrics`;
    },
    getCommentsRouteName() {
      return `${CONTEXTS.THIRD_PARTY.baseUrl}-products-comments`;
    },
    async getProductById() {
      this.isLoading = true;
      try {
        this.currentProduct = await getProductById(this.productId);
      } catch (error) {
        this.hasError = true;
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped>
header.pb-6.sm\:pb-12 {
  padding-bottom: 0;
}
</style>
