<template>
  <div class="mt-2 flex text-sm leading-1 text-gray-800 text-left">
    <calendar-svg class="flex-shrink-0 mr-3 h-5 w-5 text-gray-800" />
    <div class="flex flex-col">
      <div v-if="startDate" class="flex">
        Starts on
        <date-tooltip :date="startDate" class="ml-1" />
      </div>
      <p v-else>Undefined start date</p>
      <div v-if="endDate" class="flex">
        Ends on
        <date-tooltip :date="endDate" class="ml-1" />
      </div>
      <p v-else>Undefined end date</p>
    </div>
  </div>
</template>
<script>
import CalendarSvg from '@/components/icons/CalendarSvg';
import DateTooltip from '@/components/atoms/DateTooltip/DateTooltip';

export default {
  name: 'MetaCalendar',
  components: { DateTooltip, CalendarSvg },
  props: {
    startDate: undefined,
    endDate: undefined,
  },
};
</script>
