var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    { attrs: { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 18 18" } },
    [
      _c("path", {
        staticClass: "fill-current",
        attrs: {
          d: "M16.23,9c-2-2-3.94-3.92-5.9-5.9L10.17,3a1.91,1.91,0,0,0-2.59.22C5.64,5.16,3.7,7.11,1.75,9.05l-.09.09a.7.7,0,0,0,0,.94.71.71,0,0,0,1,0l.48-.48.11-.09v4.77a1.68,1.68,0,0,0,0,.45,1.35,1.35,0,0,0,1.38,1H7.14A.73.73,0,0,0,8,15V11.42c0-.32.11-.43.43-.43H9.57c.35,0,.45.11.45.46V15a.7.7,0,0,0,.78.77c.83,0,1.67,0,2.5,0a1.93,1.93,0,0,0,.42,0,1.37,1.37,0,0,0,1-1.39V9.46l.06.07a7.68,7.68,0,0,0,.55.59.68.68,0,0,0,1-.1A.72.72,0,0,0,16.23,9Z",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }