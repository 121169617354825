import { api } from '.';
import QueryParamsBuilder from '@/model/shared/QueryParamsBuilder';
import { PRODUCT_RESOURCE } from '@/services/keys';
import ResponseBuilder from '@/model/shared/ResponseBuilder';
import ThirdPartyEntityParser from '@/entities/ThirdParty/ThirdPartyEntityParser';

/**
 *
 * @param {String} productId
 * @returns {Promise.<{data: object,meta:EndpointMeta}>}
 */
export async function getProductById(productId) {
  const params = new QueryParamsBuilder();
  const partialUrl = api.createUrl({
    [PRODUCT_RESOURCE]: productId,
  });
  params.addInclude(['client', 'advertiser', 'comments', 'author']);
  const { data } = await api.get(partialUrl, params?.build ? params.build() : params);
  return new ResponseBuilder(ThirdPartyEntityParser.parse(data), data.meta).build().data;
}
