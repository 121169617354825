import { mapActions } from 'vuex';
import { deleteById } from '@/services/shared/deleteById';
import { Toast } from '@/model/shared/Toast';
import { CREATE_TOAST } from '@/store/modules/toast/keys';

export const deleteModalMixin = {
  data: () => ({
    selectedId: null,
    api: null,
    showModal: false,
    hasError: false,
    currentApi: null,
  }),
  methods: {
    ...mapActions({ createToast: CREATE_TOAST }),
    async deleteElementById() {
      // TODO selected item instead of id
      const item = this.items.find(item => this.selectedId === item.id) || null;
      const title = 'Delete done';
      let message = 'Delete was successfully';

      try {
        await deleteById(this.resource, this.selectedId, this.currentApi);
        if (this.getTableItems) {
          this.addQueryParams({ page: 1 });
          this.getTableItems();
        }
        if (item !== null && item.name) {
          message = `Delete of ${item.name} was successfully`;
        }
        this.deleteFromCacheById(this.selectedId);
        this.createToast(Toast.success(title, message));
      } catch (error) {
        this.createToast(Toast.error('Delete was not possible', error.message));
        this.hasError = true;
      } finally {
        this.selectedId = null;
        this.showModal = false;
      }
    },
    openModal({ id }) {
      this.selectedId = id;
      this.showModal = true;
    },
  },
};
